class Login {
    private form: HTMLFormElement;
    private formFields: HTMLElement;

    constructor(form: HTMLFormElement) {
        this.form = form;
        this.formFields = this.form.querySelector(".form-fields");

        this.form.addEventListener("submit", async (event: SubmitEvent) => {
            event.preventDefault();
            const formData = new FormData(this.form);
            const response = await fetch("/", {
                method: "POST",
                body: formData,
                headers: new Headers({
                    Accept: "application/json",
                }),
            });

            const result = await response.json();
            if (result.errorCode) {
                let error = this.formFields.querySelector(".error");
                if (!error) {
                    error = document.createElement("p");
                }
                error.classList.add("errors");
                error.innerHTML = result.message;
                this.formFields.appendChild(error);
            } else {
                window.location.href = result.returnUrl + this.form.dataset.path;
            }
        });
    }
}

export { Login };
