class PasswordHandle {
    private passwordButtons: NodeListOf<HTMLButtonElement>;

    constructor(passwordButtons: NodeListOf<HTMLButtonElement>) {
        this.passwordButtons = passwordButtons;

        this.passwordButtons.forEach((button) => {
            button.addEventListener("click", (event) => {
                const showPassword = button.classList.toggle("show-password");
                if (showPassword) {
                    button.parentElement.querySelector("input").type = "text";
                } else {
                    button.parentElement.querySelector("input").type = "password";
                }
            });
        });
    }
}

export { PasswordHandle };
