import { initOverlay } from "./utility/helper";

export default class ProfileOverlay {
    private container: HTMLElement;
    private openButtons: NodeListOf<HTMLButtonElement>;
    private overlays: NodeListOf<HTMLElement>;

    constructor(container: HTMLElement) {
        this.container = container;

        this.openButtons = this.container.querySelectorAll(".edit-address, .add-address");
        this.overlays = this.container.querySelectorAll(".overlay");

        this.openButtons.forEach((button) => {
            button.addEventListener("click", () => {
                let overlayClass = "";

                button.classList.forEach((c) => {
                    if (c !== "edit-address") {
                        overlayClass = c;
                    }
                });

                this.openOverlay(overlayClass);
            });
        });

        this.overlays.forEach((overlay) => {
            initOverlay(overlay);
        });
    }

    private openOverlay(overlayClass: string) {
        this.overlays.forEach((overlay) => {
            if (overlay.classList.contains(`overlay-${overlayClass}`)) {
                overlay.style.display = "block";
            }
        });
    }
}
