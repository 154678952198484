import { displayError, initOverlay } from "./utility/helper";

export default class SelectAddress {
    private changeAddressButton: HTMLButtonElement;
    private addressOverlay: HTMLElement;
    private fieldKey: string;
    private addresses: NodeListOf<HTMLElement>;
    private saveButton: HTMLButtonElement;
    private userId: string;
    private csrfToken: string;
    private redirectUrl: string;

    constructor(changeAddressButton, addressOverlay, fieldKey) {
        this.changeAddressButton = changeAddressButton;
        this.addressOverlay = addressOverlay;
        this.fieldKey = fieldKey;
        const csrfField = <HTMLInputElement>this.addressOverlay.querySelector('input[name="CRAFT_CSRF_TOKEN"]');
        this.csrfToken = csrfField.value;
        const redirectField = <HTMLInputElement>this.addressOverlay.querySelector('input[name="redirect"]');
        this.redirectUrl = redirectField.value.substring(redirectField.value.indexOf("http"));

        this.changeAddressButton.addEventListener("click", () => {
            this.addressOverlay.style.display = "block";
            this.userId = this.changeAddressButton.dataset.userId;
        });

        this.addresses = this.addressOverlay.querySelectorAll(".address");
        this.saveButton = this.addressOverlay.querySelector(".save-selection");

        initOverlay(this.addressOverlay);
        this.initAddresses();
        this.initSaveButton();
    }

    private initAddresses() {
        this.addresses.forEach((address) => {
            address.addEventListener("click", async () => {
                if (!address.classList.contains("pending")) {
                    if (address.classList.contains("selected")) {
                        this.addresses.forEach((a) => {
                            a.classList.add("pending");
                            this.saveButton.disabled;
                            this.saveButton.classList.add("loading");
                        });
                        await this.saveNewDefaultAddress(address);
                    } else {
                        this.addresses.forEach((a) => {
                            a.classList.remove("selected");
                        });
                        address.classList.add("selected");
                    }
                }
            });
        });
    }

    private initSaveButton() {
        this.saveButton.addEventListener("click", async () => {
            this.saveButton.disabled = true;
            this.saveButton.classList.add("loading");
            let newAddress;
            this.addresses.forEach((address) => {
                address.classList.add("pending");
                if (address.classList.contains("selected")) {
                    newAddress = address;
                }
            });

            await this.saveNewDefaultAddress(newAddress);
        });
    }

    private async saveNewDefaultAddress(address: HTMLElement) {
        const url = "/";
        const addressId = address.dataset.id;
        const formData = new FormData();

        formData.append("action", "users/save-user");
        formData.append("CRAFT_CSRF_TOKEN", this.csrfToken);
        formData.append("userId", this.userId);
        formData.append(`fields[${this.fieldKey}][]`, addressId);

        const response = await fetch(url, {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: formData,
        });

        if (response.status === 500) {
            displayError(this.saveButton, response.statusText);
        } else {
            const result = await response.json();
            if (result.error) {
                displayError(this.saveButton, result.error);
            } else {
                this.saveButton.classList.remove("loading");
                this.saveButton.disabled = false;
                this.addresses.forEach((address) => {
                    address.classList.remove("pending");
                });
                const previousLocation = window.location.toString();
                window.location.replace(this.redirectUrl);
                if (this.redirectUrl === previousLocation) {
                    window.location.reload();
                }
            }
        }
    }
}
