import { Cart, Item } from "./Cart";

const productPage = <HTMLElement>document.querySelector(".product-page");
const cart = new Cart();

if (productPage) {
    const addSampleButton = <HTMLButtonElement>productPage.querySelector(".add-sample");
    const thumbnails = Array.from(<NodeListOf<HTMLImageElement>>productPage.querySelectorAll(".thumbnail"));
    let sampleInCart = cart.getCart().find((i) => i.id === productPage.dataset.itemNumber && i.amount.sample === 1);

    if (sampleInCart) {
        addSampleButton.innerText = "Zum Warenkorb";
    }

    addSampleButton.addEventListener("click", () => {
        if (!sampleInCart) {
            const entry: Item = {
                id: productPage.dataset.itemNumber,
                color: {
                    id: productPage.dataset.itemNumber,
                    code: productPage.dataset.itemCode,
                    name: productPage.dataset.itemName,
                    configRollCost: Number(productPage.dataset.configRollCost),
                    configCutCost: Number(productPage.dataset.configCutCost),
                    configOrderCost: Number(productPage.dataset.configOrderCost),
                    deliveryTime: productPage.dataset.deliveryTime,
                    image: thumbnails[thumbnails.length - 1].src.replace("/_620x465_crop_center-center_none", ""),
                    specialSizeSelected: false,
                    pricePerSquareMeter: Number(productPage.dataset.costLfm),
                    pricePerSquareMeterRoll: Number(productPage.dataset.costRoll),
                    rollLength: Number(productPage.dataset.rollLength),
                    thickness: Number(productPage.dataset.thickness),
                    height: Number(productPage.dataset.height),
                    specialHeight: Number(productPage.dataset.specialHeight),
                    product: productPage.dataset.productName,
                },
                amount: { sample: 1 },
                cost: {},
            };

            sampleInCart = cart.addToCart(entry);
            if (sampleInCart) {
                addSampleButton.innerText = "Zum Warenkorb";
            }
        } else {
            window.location.href = "/?step=cart#Configurator";
        }
    });
}
