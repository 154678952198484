const productCatalog = <HTMLElement>document.querySelector(".product-line-catalog");

if (productCatalog) {
    const lineSelectButtons = <NodeListOf<HTMLButtonElement>>productCatalog.querySelectorAll(".product-filter-button");
    const itemContainers = <NodeListOf<HTMLElement>>productCatalog.querySelectorAll(".product-catalog-items");

    lineSelectButtons.forEach((button) => {
        button.addEventListener("click", () => {
            if (!button.classList.contains("active")) {
                itemContainers.forEach((itemContainer) => {
                    if (itemContainer.classList.contains(`id-${button.dataset.id}`)) {
                        itemContainer.classList.add("active");
                    } else {
                        itemContainer.classList.remove("active");
                    }
                });
                lineSelectButtons.forEach((b) => {
                    if (b === button) {
                        b.classList.add("active");
                    } else {
                        b.classList.remove("active");
                    }
                });
            }
        });
    });
}
