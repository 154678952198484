const galleries = <NodeListOf<HTMLElement>>document.querySelectorAll(".gallery");

galleries.forEach((gallery) => {
    const big = <HTMLImageElement>gallery.querySelector(".big-image");
    const previews = <NodeListOf<HTMLImageElement>>gallery.querySelectorAll(".preview");

    previews.forEach((preview) => {
        preview.addEventListener("click", () => {
            big.src = preview.src;
            previews.forEach((p) => {
                p.classList.remove("current");
            });
            preview.classList.add("current");
        });
    });
});
