export default class TabSwitcher {
    private container: HTMLElement;
    private tabButtons: NodeListOf<HTMLButtonElement>;
    private tabs: NodeListOf<HTMLElement>;

    constructor(container: HTMLElement) {
        this.container = container;
        this.tabButtons = this.container.querySelectorAll(".tab");
        this.tabs = this.container.querySelectorAll(".tab-site");

        this.tabButtons.forEach((button) => {
            button.addEventListener("click", () => {
                if (button.classList.contains("active")) {
                    return;
                } else {
                    let tabName = "";
                    button.classList.forEach((className) => {
                        if (className !== "tab" && className !== "active" && className !== "h4") {
                            tabName = className;
                            return;
                        }
                    });
                    this.setActiveState(tabName);
                }
            });
        });
    }

    private setActiveState(className: string) {
        this.toggleActiveStage(this.tabButtons, className);
        this.toggleActiveStage(this.tabs, className);
    }

    private toggleActiveStage(elements, className) {
        elements.forEach((element) => {
            if (element.classList.contains(className)) {
                element.classList.add("active");
            } else {
                element.classList.remove("active");
            }
        });
    }
}
