type Selection = {
    element: HTMLElement;
    image: HTMLImageElement;
    id: string;
    selected: boolean;
};

type Product = {
    element: HTMLElement;
    id: string;
    selected: boolean;
};

type Color = {
    element: HTMLElement;
    image: HTMLImageElement;
    id: string;
    itemNumber: string;
    itemCode: string;
    selected: boolean;
    minCut: number;
    configRollCost: number;
    configCutCost: number;
    configOrderCost: number;
    deliveryTime: string;
    specialSizeAvailable: boolean;
    specialSizeSelected: boolean;
};

type Colors = {
    element: HTMLElement;
    id: string;
    selected: boolean;
    colors: Color[];
};

class ColorSelector {
    private container: HTMLElement;
    public previews: Product[] = [];
    public selections: Selection[] = [];
    public colors: Colors[] = [];
    public currentColor: Color;

    constructor(container: HTMLElement, productLineId: string) {
        this.container = container;
        this.container.querySelectorAll(".small-preview").forEach((preview: HTMLElement) => {
            this.previews.push({
                element: preview,
                id: preview.dataset.id,
                selected: preview.classList.contains("invisible"),
            });
        });

        this.container.querySelectorAll(".selected-item").forEach((selection: HTMLElement) => {
            this.selections.push({
                element: selection,
                image: selection.querySelector(".image"),
                id: selection.dataset.id,
                selected: selection.classList.contains("selected"),
            });
        });

        this.container.querySelectorAll(".colors").forEach((color: HTMLElement) => {
            const colors: Color[] = [];
            color.querySelectorAll(".item > .image-and-highlight").forEach((item: HTMLElement) => {
                const c = {
                    element: item,
                    image: item.querySelector("img"),
                    id: item.dataset.id,
                    itemNumber: item.dataset.itemNumber,
                    itemCode: item.dataset.itemCode,
                    selected: item.classList.contains("selected"),
                    minCut: Number(item.dataset.minCut),
                    configRollCost: Number(item.dataset.configRollCost),
                    configCutCost: Number(item.dataset.configCutCost),
                    configOrderCost: Number(item.dataset.configOrderCost),
                    deliveryTime: item.dataset.deliveryTime,
                    specialSizeAvailable: item.dataset.specialSizeAvailable === "true",
                    specialSizeSelected: false,
                };

                colors.push(c);

                if (color.dataset.id === productLineId && c.selected) {
                    this.currentColor = c;
                }
            });
            this.colors.push({
                element: color,
                id: color.dataset.id,
                selected: color.classList.contains("selected"),
                colors: colors,
            });
        });
    }

    public setCurrentColor(productId, colorId) {
        this.colors.forEach((colors) => {
            if (colors.id === productId) {
                colors.colors.forEach((color) => {
                    if (color.id === colorId) {
                        this.currentColor = color;
                        return;
                    }
                });
            }
        });
    }

    public getSelectedColorForProductLine(productLineID: string) {
        this.colors.forEach((colors) => {
            if (colors.id === productLineID) {
                colors.colors.forEach((color) => {
                    if (color.selected) {
                        this.currentColor = color;
                    }
                });
            }
        });
        return this.currentColor;
    }
}

export { ColorSelector, Color };
