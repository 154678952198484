import { Color } from "./ColorSelector";
import { ProductBox } from "./ProductLine";
import { applyDiscount, calculateCost, getCurrencyOutputString } from "../utility/helper";

type ChangeAmount = {
    increase: HTMLButtonElement;
    decrease: HTMLButtonElement;
    current: HTMLElement;
};

class Amount {
    private container: HTMLElement;
    private minElement: HTMLElement;
    private maxElement: HTMLElement;
    private currentHeight: number;
    private currentSquareMeterCost: number;
    private currentRollCost: number;
    private currentRollLength: number;
    private rollCost: HTMLElement;
    private cutCost: HTMLElement;
    private currentRollLengthText: HTMLElement;
    private customerDiscount: Object;

    public inputLfmCut: HTMLInputElement;
    public selectedItem: Color;
    public rolls: ChangeAmount;
    public lfmMobile: ChangeAmount;
    public lfmDesktop: HTMLElement;
    public specialSizeButton: HTMLElement;
    public addRollsToCart: HTMLButtonElement;
    public addLfmToCart: HTMLButtonElement;
    public addSampleToCart: HTMLButtonElement;
    public addAccessoiresToCart: NodeListOf<HTMLButtonElement>;
    public cartIcon: HTMLElement;

    constructor(container: HTMLElement) {
        this.container = container;
        const configContainer = <HTMLElement>this.container.querySelector(".config");
        this.customerDiscount = JSON.parse(configContainer.dataset.customerDiscount);

        const selectedElement: HTMLElement = this.container.querySelector(".selected-item");
        // values will be overwritten when calling setSelectedItem which is done when starting the site
        this.selectedItem = {
            element: selectedElement,
            selected: true,
            id: selectedElement.dataset.id,
            image: selectedElement.querySelector("img"),
            itemNumber: "",
            itemCode: "",
            minCut: 1,
            configRollCost: 1,
            configCutCost: 1,
            configOrderCost: 1,
            deliveryTime: "",
            specialSizeAvailable: false,
            specialSizeSelected: false,
        };

        const rollsAmount: HTMLElement = this.container.querySelector(".rolls-amount");
        this.rolls = {
            increase: rollsAmount.querySelector(".increase"),
            decrease: rollsAmount.querySelector(".decrease"),
            current: rollsAmount.querySelector(".current"),
        };

        const cutAmount: HTMLElement = this.container.querySelector(".current-cut.mobile");
        this.lfmMobile = {
            increase: cutAmount.querySelector(".increase"),
            decrease: cutAmount.querySelector(".decrease"),
            current: cutAmount.querySelector(".current"),
        };
        this.lfmDesktop = this.container.querySelector(".current-cut.desktop");

        this.minElement = this.container.querySelector(".current-min");
        this.maxElement = this.container.querySelector(".current-max");
        this.inputLfmCut = this.container.querySelector(".lfm-cut");
        this.rollCost = this.container.querySelector(".info-and-price-roll .current-cost");
        this.cutCost = this.container.querySelector(".info-and-price-cut .current-cost");
        this.currentRollLengthText = this.container.querySelector(".step-description .small-text");

        this.specialSizeButton = this.container.querySelector(".special-size");
        this.addRollsToCart = this.container.querySelector(".add-roll");
        this.addLfmToCart = this.container.querySelector(".add-cut");
        this.addSampleToCart = this.container.querySelector(".add-sample");
        this.addAccessoiresToCart = this.container.querySelectorAll(".add-accessory");

        this.cartIcon = this.container.querySelector(".cart-and-items .cart");
    }

    public setSelectedItem(productLine: ProductBox, color: Color) {
        this.selectedItem.id = productLine.id;
        this.selectedItem.minCut = color.minCut;
        this.selectedItem.itemNumber = color.itemNumber;
        this.selectedItem.itemCode = color.itemCode;
        this.selectedItem.configRollCost = color.configRollCost;
        this.selectedItem.configCutCost = color.configCutCost;
        this.selectedItem.configOrderCost = color.configOrderCost;
        this.selectedItem.deliveryTime = color.deliveryTime;
        this.selectedItem.specialSizeAvailable = color.specialSizeAvailable;
        this.selectedItem.specialSizeSelected = false;

        this.updateElement(productLine, color);

        this.currentHeight = productLine.height;
        this.currentSquareMeterCost = productLine.costLfm;
        this.currentRollCost = productLine.costRoll;
        this.currentRollLength = productLine.rollLength;
        this.currentRollLengthText.innerHTML = this.currentRollLengthText.innerHTML.replace(
            new RegExp(" \\d+ "),
            " " + this.currentRollLength.toString() + " "
        );
        this.changeLfmAmount(this.selectedItem.minCut);
        this.minElement.innerHTML = `${this.selectedItem.minCut} m`;
        this.maxElement.innerHTML = `${this.currentRollLength - 0.5} m`;
        this.inputLfmCut.min = this.selectedItem.minCut.toString();
        this.inputLfmCut.max = (this.currentRollLength - 0.5).toString();
        this.inputLfmCut.value = this.selectedItem.minCut.toString();
        this.changeRollsAmount(0);
    }

    public changeRollsAmount(change: number) {
        let newAmount = Number(this.rolls.current.innerHTML) + change;
        if (newAmount < 1) {
            newAmount = 1;
        }
        this.rolls.current.innerHTML = newAmount.toString();
        const discount = this.customerDiscount[this.selectedItem.id]["roll"] ?? 0;
        if (this.rollCost) {
            this.rollCost.innerHTML = ` ca. ${getCurrencyOutputString(
                applyDiscount(
                    calculateCost(this.currentRollLength, newAmount, this.currentHeight, this.currentRollCost),
                    discount
                )
            )}`;
        }
    }

    public changeLfmAmountByValue(change: number) {
        let sanitizedValue = this.sanitizeLfmAmount(Number(this.lfmMobile.current.innerHTML) + change);
        this.changeLfmAmount(sanitizedValue);
    }

    public changeLfmAmountAbs(value: number) {
        const sanitizedValue = this.sanitizeLfmAmount(value);
        this.changeLfmAmount(sanitizedValue);
    }

    private changeLfmAmount(value: number) {
        this.lfmMobile.current.innerHTML = value.toString();
        this.lfmDesktop.innerHTML = value.toString();
        const discount = this.customerDiscount[this.selectedItem.id]["cut"] ?? 0;
        if (this.cutCost) {
            this.cutCost.innerHTML = getCurrencyOutputString(
                applyDiscount(calculateCost(value, 1, this.currentHeight, this.currentSquareMeterCost), discount)
            );
        }
    }

    private updateElement(productLine: ProductBox, color: Color) {
        this.selectedItem.element.dataset.id = productLine.id;
        this.selectedItem.element.dataset.costLfm = productLine.costLfm.toString();
        this.selectedItem.element.dataset.costRoll = productLine.costRoll.toString();
        this.selectedItem.element.dataset.thickness = productLine.thickness.toString();
        this.selectedItem.element.dataset.height = productLine.height.toString();
        this.selectedItem.element.dataset.minCut = color.minCut.toString();
        this.selectedItem.element.dataset.configRollCost = color.configRollCost.toString();
        this.selectedItem.element.dataset.configCutCost = color.configCutCost.toString();
        this.selectedItem.element.dataset.configOrderCost = color.configOrderCost.toString();
        this.selectedItem.element.dataset.deliveryTime = color.deliveryTime;
        this.selectedItem.element.dataset.itemNumber = color.itemNumber;
        this.selectedItem.element.dataset.itemCode = color.itemCode;
        this.selectedItem.image.src = color.image.src.replace("_colorThumb", "");
        this.selectedItem.image.alt = color.image.alt;
        this.selectedItem.element.querySelector(".title").innerHTML = color.id;
        this.selectedItem.element.querySelector(".info-title").innerHTML = productLine.id;
        const priceCut = this.selectedItem.element.querySelector(".price .cut");
        const discountCut = this.customerDiscount[this.selectedItem.id]["cut"] ?? 0;
        const discountRoll = this.customerDiscount[this.selectedItem.id]["roll"] ?? 0;
        if (priceCut) {
            priceCut.innerHTML = `${getCurrencyOutputString(applyDiscount(productLine.costLfm, discountCut))}/m²`;
        }
        const priceRoll = this.selectedItem.element.querySelector(".price .roll");
        if (priceRoll) {
            priceRoll.innerHTML = `${getCurrencyOutputString(applyDiscount(productLine.costRoll, discountRoll))}/m²`;
        }
        this.selectedItem.element.querySelector(
            ".dimensions"
        ).innerHTML = `${productLine.thickness} mm | ${productLine.height} mm`;
        this.specialSizeButton.classList.toggle("hidden", !this.selectedItem.specialSizeAvailable);
        this.specialSizeButton.classList.toggle("selected", this.selectedItem.specialSizeSelected);
    }

    public setSpecialHeight(productLine: ProductBox, color: Color) {
        this.selectedItem.specialSizeSelected = !this.selectedItem.specialSizeSelected;

        if (this.selectedItem.specialSizeSelected) {
            this.selectedItem.configRollCost = Number(this.specialSizeButton.dataset.specialSizeConfigRollCost);
            this.selectedItem.configCutCost = Number(this.specialSizeButton.dataset.specialSizeConfigCutCost);
            this.selectedItem.configOrderCost = Number(this.specialSizeButton.dataset.specialSizeConfigOrderCost);
            this.selectedItem.minCut = Number(this.specialSizeButton.dataset.specialSizeMinCut);
            this.selectedItem.deliveryTime = this.specialSizeButton.dataset.specialSizeDeliveryTime;
        } else {
            this.selectedItem.configRollCost = color.configRollCost;
            this.selectedItem.configCutCost = color.configCutCost;
            this.selectedItem.configOrderCost = color.configOrderCost;
            this.selectedItem.minCut = color.minCut;
            this.selectedItem.deliveryTime = color.deliveryTime;
        }

        this.changeLfmAmount(this.selectedItem.minCut);
        this.minElement.innerHTML = `${this.selectedItem.minCut} m`;
        this.inputLfmCut.min = this.selectedItem.minCut.toString();
        this.inputLfmCut.value = this.selectedItem.minCut.toString();
        this.specialSizeButton.classList.toggle("selected", this.selectedItem.specialSizeSelected);
        const height = this.selectedItem.specialSizeSelected ? productLine.specialHeight : productLine.height;
        this.currentHeight = height;
        this.changeRollsAmount(0);
        const dimensions = this.selectedItem.element.querySelector(".dimensions");
        dimensions.innerHTML = `${productLine.thickness} mm | ${height} mm`;
        dimensions.classList.toggle("pop-out", true);
        setTimeout(() => {
            dimensions.classList.toggle("pop-out", false);
        }, 1000);
    }

    private sanitizeLfmAmount(newAmount) {
        if (newAmount < this.selectedItem.minCut) {
            newAmount = this.selectedItem.minCut;
        } else if (newAmount > 30) {
            newAmount = 30;
        }
        return newAmount;
    }

    public getContainer() {
        return this.container;
    }
}

export { Amount };
