import { Item } from "../Cart";
import { applyDiscount, getCurrencyOutputString } from "../utility/helper";

type ItemNode = {
    id: string;
    item: HTMLElement;
    cartItem: Item;
};

class CartOverview {
    private container: HTMLElement;
    private itemList: HTMLElement;
    private template: HTMLElement;
    private items: ItemNode[] = [];
    private totalPrice = 0;
    private totalConfigPrice = 0;

    public deleteItemButtons: NodeListOf<HTMLButtonElement>;
    public continueShoppingButton: HTMLButtonElement;
    public orderButton: HTMLButtonElement;
    public loginToOrderButton: HTMLButtonElement;
    public customerDiscount: Object;
    public customerTax: number;

    constructor(container: HTMLElement) {
        this.container = container;
        this.continueShoppingButton = this.container.querySelector(".shopping-buttons > .continue-shopping");
        this.itemList = this.container.querySelector(".cart-list");
        this.template = this.container.querySelector(".template");
        this.deleteItemButtons = this.container.querySelectorAll(".delete-button");
        this.orderButton = this.container.querySelector(".order-now");
        this.loginToOrderButton = this.container.querySelector(".login-to-order");
        this.customerDiscount = JSON.parse(
            (this.container.querySelector(".cart-summary") as HTMLElement).dataset.customerDiscount
        );
        this.customerTax = Number((this.container.querySelector(".cart-summary") as HTMLElement).dataset.customerTax);
    }

    public addCartItem(item: Item, edited: boolean) {
        let itemNode: ItemNode = this.findItem(item.id);
        if (!itemNode) {
            itemNode = { id: item.id, item: <HTMLElement>this.template.cloneNode(true), cartItem: item };
            itemNode.item.classList.toggle("template", false);
        }

        this.setItemAmounts(item, itemNode);
        this.setItemPrice(item, itemNode);

        if (!edited) {
            CartOverview.setItemInformation(item, itemNode);
            this.itemList.appendChild(itemNode.item);
            this.items.push(itemNode);
        }

        this.deleteItemButtons = this.container.querySelectorAll(".delete-button");
        this.setTotalPrice();
    }

    private static setItemInformation(item, itemNode) {
        let imageSrc = "";
        let name = "";
        let product = "";
        let info = "";

        if (item.color) {
            imageSrc = item.color.image;
            name = item.color.name;
            product = item.color.product;
            let height = item.color.height;
            if (item.color.specialSizeSelected) {
                height = item.color.specialHeight;
            }
            info = `${item.color.thickness} mm | ${height} mm`;
        } else if (item.accessory) {
            imageSrc = item.accessory.image;
            product = item.accessory.name;
            info = item.accessory.amount;
        }

        const image = itemNode.item.querySelector(".image-and-title .image") as HTMLImageElement;
        image.src = imageSrc;
        image.alt = name;
        itemNode.item.querySelector(".image-and-title .title").innerHTML = name;
        itemNode.item.querySelector(".info .info-title").innerHTML = product;
        itemNode.item.querySelector(".info .dimensions").innerHTML = info;
        itemNode.item.querySelector(".delete-button").dataset.id = item.id;
    }

    private setItemAmounts(item, itemNode) {
        if (item.amount.rolls) {
            itemNode.item.querySelector(".rolls-number").innerHTML = item.amount.rolls.toString();
            this.toggleInvisible(".rolls", itemNode, false);
        } else {
            this.toggleInvisible(".rolls", itemNode, true);
        }
        if (item.amount.lfm) {
            itemNode.item.querySelector(".cut-number").innerHTML = item.amount.lfm.toString();
            this.toggleInvisible(".cut", itemNode, false);
        } else {
            this.toggleInvisible(".cut", itemNode, true);
        }
        if (item.amount.sample) {
            itemNode.item.querySelector(".sample-number").innerHTML = item.amount.sample.toString();
            this.toggleInvisible(".sample", itemNode, false);
        } else {
            this.toggleInvisible(".sample", itemNode, true);
        }
        if (item.amount.piece) {
            itemNode.item.querySelector(".piece-number").innerHTML = item.amount.piece.toString();
            this.toggleInvisible(".piece", itemNode, false);
        } else {
            this.toggleInvisible(".piece", itemNode, true);
        }
        if (item.color && item.color.specialSizeSelected) {
            this.toggleInvisible(".special-size", itemNode, false);
        } else {
            this.toggleInvisible(".special-size", itemNode, true);
        }
    }

    private setItemPrice(item: Item, itemNode: ItemNode) {
        if (item.color) {
            itemNode.item.querySelector(".price-and-delivery .config-cost").innerHTML = getCurrencyOutputString(
                item.cost.totalConfigCost
            );
            itemNode.item.querySelector(".price-and-delivery .delivery-time").innerHTML = item.color.deliveryTime;
        }
        if (item.accessory) {
            itemNode.item.querySelector(".price-and-delivery .delivery-time").innerHTML = item.accessory.deliveryTime;
        }

        itemNode.item.querySelector(".price-and-delivery .price").innerHTML = getCurrencyOutputString(
            this.calculateItemPrice(item)
        );
    }

    private toggleInvisible(selector: string, itemNode: ItemNode, force: boolean) {
        itemNode.item.querySelectorAll(selector).forEach((element) => {
            element.classList.toggle("invisible", force);
        });
    }

    private findItem(id) {
        return this.items.find((item) => item.id === id);
    }

    private calculateItemPrice(item: Item) {
        let totalCost = 0;

        if (item.amount.rolls) {
            totalCost += applyDiscount(item.cost.rolls, this.customerDiscount[item.color.product]["roll"]);
        }
        if (item.amount.lfm) {
            totalCost += applyDiscount(item.cost.lfm, this.customerDiscount[item.color.product]["cut"]);
        }
        if (item.amount.piece) {
            totalCost += item.cost.piece;
        }

        return totalCost;
    }

    private setTotalPrice() {
        this.totalPrice = 0;
        this.totalConfigPrice = 0;
        this.items.forEach((item) => {
            this.totalPrice += this.calculateItemPrice(item.cartItem);
            this.totalConfigPrice += item.cartItem.cost.totalConfigCost;
        });

        const tax = (this.totalPrice + this.totalConfigPrice) * (this.customerTax / 100);

        this.container.querySelector(".cart-summary .sum-of-items").innerHTML = getCurrencyOutputString(
            this.totalPrice
        );
        this.container.querySelector(".cart-summary .config").innerHTML = getCurrencyOutputString(
            this.totalConfigPrice
        );
        this.container.querySelector(".cart-summary .tax").innerHTML = getCurrencyOutputString(tax);
        this.container.querySelector(".cart-summary .total-cost").innerHTML = getCurrencyOutputString(
            this.totalPrice + tax + this.totalConfigPrice
        );
    }

    public removeCartItem(deleteButton: HTMLButtonElement) {
        this.deleteItemButtons = this.container.querySelectorAll(".delete-button");
        this.items = this.items.filter((item) => item.id !== deleteButton.dataset.id);
        deleteButton.parentElement.remove();
        this.setTotalPrice();
    }

    public removeCartItems() {
        this.items = [];
        this.itemList.innerHTML = "";
    }
}

export { CartOverview };
